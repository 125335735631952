import React from 'react'

import Page from '../../components/page'
import withRoot from '../../withRoot'
import { InfoNote, WarningNote } from '../../components/highlight'

import {
  Typography,
  Icon,
  Grid,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  space: {
    margin: theme.spacing(1)
  }
})

@withStyles(styles)
class Tutorial extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Icon className={classes.icolink}>{this.props.icon}</Icon>
        <Typography className={classes.space}>
          <a className={classes.link} href={this.props.url} target="_blank" rel="noopener noreferrer">{this.props.title}</a>
        </Typography>
        <Typography variant="subtitle2" className={classes.space}>By {this.props.author}</Typography>
      </Grid>
    )
  }
}

@withRoot
@withStyles(styles)
export default class Tutorials extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} title="Tutorials">
        <Typography className={classes.space}>
          You will find here some tutorials made by FitFace users.<br />We hope they will be usefull to build great designs.
        </Typography>

        <Tutorial icon="textsms" author="Frank" title="How to use the opacity to hide/show an element?" url="https://www.facebook.com/groups/FitFace.For.Fitbit/permalink/1770144976451519/" />
        <Tutorial icon="textsms" author="Frank" title="Flashing colon" url="https://www.facebook.com/groups/FitFace.For.Fitbit/permalink/1770513016414715/" />
        <Tutorial icon="textsms" author="Frank" title="How to make a progess bar for the steps?" url="https://www.facebook.com/groups/FitFace.For.Fitbit/permalink/1771294336336583/" />
        <Tutorial icon="textsms" author="Frank" title="Transparent elements" url="https://www.facebook.com/groups/FitFace.For.Fitbit/permalink/1771998316266185/" />
        <Tutorial icon="textsms" author="Frank" title="Transparent and overlapping numbers" url="https://www.facebook.com/groups/FitFace.For.Fitbit/permalink/1772018676264149/" />
        <Tutorial icon="videocam" author="Geeves" title="How to use images to display the weather condition?" url="https://www.youtube.com/watch?v=7DIt5gUT3xw" />
        <Tutorial icon="videocam" author="Geeves" title="Using images for clock hands" url="https://www.youtube.com/watch?v=Qs2a4XIwSmc" />
        <Tutorial icon="videocam" author="Maria" title="Tap to change screen" url="https://www.youtube.com/watch?v=EzXuekjEEcc" />
        <Tutorial icon="videocam" author="Chops" title="Day/night rotation & Weather" url="https://www.youtube.com/watch?v=DPLrHa015N0" />
        <Tutorial icon="videocam" author="Chops" title="Adding Colour" url="https://www.youtube.com/watch?v=YXOwfHWw5uI" />
        <Tutorial icon="videocam" author="Chops" title="Creating a Battery Bar & Progress Arc" url="https://www.youtube.com/watch?v=9hmPS6K42f8" />
        <Tutorial icon="videocam" author="Chops" title="Creating an arc with a rounded end" url="https://www.youtube.com/watch?v=6SB4Pmc1S6g" />
        <Tutorial icon="videocam" author="DawgTilDeath" title="FitFace image fade with battery % used" url="https://www.youtube.com/watch?v=KoyciCRkHYE" />

        <InfoNote>You&apos;ve made a FitFace tutorial. That&apos;s awesome. Send us a link if you want to appear here.</InfoNote>
        <WarningNote>If you find some obsolete or false information, please tell us.</WarningNote>
      </Page>
    )
  }
}
